<template>
  <div class="app-card-with-link flex flex-col bg-white rounded-8 px-24 py-19">
    <h1 class="title flex-initial pb-16 mb-16 text-20 font-semibold text-grey-900">{{ title }}</h1>
    <p
      v-if="description"
      class="flex-auto overflow-hidden text-16 mb-16 font-semibold text-grey-500 leading-150"
      v-html="description"
    />
    <div class="flex-initial text-right">
      <a class="font-semibold text-15 text-blue-500" :href="linkUrl" target="_blank">
        {{ linkTitle }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppCardWithLink',
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
    },
    linkUrl: {
      type: String,
      required: true,
    },
    linkTitle: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.app-card-with-link {
  width: 100%;
  max-width: 304px;
  height: 291px;
  box-shadow: 0 4px 8px 1px theme('colors.grey.300');

  .title {
    width: 100%;
    border-bottom: 1px solid theme('colors.grey.300');
  }
}
</style>
